import {
  AfterViewChecked,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener, Injector,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import {ActivatedRoute, Router} from '@angular/router';
import { DateService } from '../shared/services/date.service';
import {HelpersService} from '../shared/services/helpers.service';
import {LocalStorageService} from '../shared/services/local-storage.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Dictionary} from '../shared/types/dictionary';
import {NgbModal, NgbPopover, NgbTabset} from '@ng-bootstrap/ng-bootstrap';
import {IsArrayPipe, IsObjectPipe, PairsPipe} from 'ngx-pipes';
import {SeatMapService} from '../shared/services/seat-map.service';
import {PaymentModuleService} from '../shared/services/payment-module.service';
import {of, Subject} from 'rxjs';
import {filter, map, switchMap, takeUntil} from 'rxjs/operators';
import {
  DOCUMENT_TYPE,
  GENDERS,
  LOYALTY_PROGRAM_ACCOUNT_SUPPORTED_PROVIDERS,
  PASSENGER_TYPES,
  PAX_FORM_TITLES,
} from '../shared/constants';
import {DataTransferService} from '../shared/services/data-transfer.service';
import {parsePhoneNumber, parsePhoneNumberFromString} from 'libphonenumber-js';
import {NotificationService} from '../shared/services/notification.service';
import {ProfilesService} from '../shared/services/profiles.service';
import {MetaService} from '../shared/services/meta.service';
import {Process} from '../shared/interfaces/show-process';
import {UmbrellaService} from '../shared/services/umbrella.service';
import {NDCApiService} from '../shared/services/ndc-api.service';
import {GtmService} from '../shared/services/gtm.service';
import {convertPhoneIntoString} from '../shared/adapters/v1_2/adapters';
import {MinMaxDate} from '../shared/interfaces/date';
import { TravelerTypePipe } from '../shared/pipes/traveler-type.pipe';
import {FakerService} from '../shared/services/faker.service';
import { SentryService } from "../shared/services/sentry.service";
import {RemarkDataToUpdate, RemarkTextToUpdate} from "../shared/interfaces/remarks";
import {OfferService} from "../shared/services/offer.service";
import {NgxIndexedDBService} from "ngx-indexed-db";
import {Preset, Qualifier} from "../shared/interfaces/presets";
import {BookingActionsState} from "../shared/models/god-mode-actions";
import {PaymentSettings} from "../shared/models/payment-settings";
import {ConfirmDialogService} from "../shared/services/confirm-dialog.service";
import {DocumentIdValidator} from "../shared/validators/documentId.validator";
import {HubAgenciesService} from "../shared/services/hub-agencies.service";
import {FormsService} from "../shared/services/forms.service";


declare var intlTelInputGlobals: any;
export enum BOOKING_STEPS {
  OFFER,
  PAX,
  REMARKS,
  RESERVATION
}

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
  providers: [TravelerTypePipe]
})
export class BookingComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('paymentFormModal', { static: true }) paymentFormModal: ElementRef;
  @ViewChild('flyerNumberSelectModal', { static: true }) flyerNumberSelectModal: ElementRef;
  @ViewChild('confirmStoreModal', { static: true }) confirmStoreModal: ElementRef;
  @ViewChild('popOver') public popover: NgbPopover;
  @ViewChild('warnings') warnings: ElementRef;
  @ViewChild(NgbTabset) private tabset: NgbTabset;


  responseError: any;
  responseWarning = [];
  allWarningsAreShown: boolean;
  isWarningsReadBtnNeeded: boolean;
  showWarnings = true;
  showLoader = false;
  showSeats;
  isEnsurePayment = false;

  bookingProcess: Process = {
    isProcess: false,
    processTitle: 'Booking...'
  };

  seatsSegments;
  orderCreateSuccess: string;
  orderCreateError: string;
  bookingResponseWarning = [];
  travelers: any;
  offer;
  isOfferExpired: boolean;
  offerInfo = {};
  isPatchForm = false;
  offerID: any;
  seatAvailability: any;
  documentOptions = DOCUMENT_TYPE;
  genders = GENDERS;
  titles: string[];
  isGenderExists = {};
  adultReferences = [];
  // form management
  form: FormGroup;
  passengerDefaults = {};
  countries = Dictionary.Countries;
  dictionaryAirlineDesignators = Dictionary.AirlineDesignators;
  bookingHasError: boolean;
  errors: any;
  validateTriggered = false;
  paymentValidateTriggered = false;
  // SSR
  ssrPassengerTitle = '';
  ssrPassengerID = '';
  ssrSelected = {};
  ssrSelectedCount = {};
  ssrSelectedText = {};
  ssrSelectedRequiredText = {};
  ssrSelectedValidation = {};
  ssrErrorMessage = '';
  // field to set first active traveler id in template
  firstTravelerID = '';
  // flag sets if provider support seat availability
  seatAvailabilityEnabled = false;
  serviceListEnabled = false;
  orderCreateWithPaymentEnabled = false;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  routeInfo = '';
  bookingSteps = BOOKING_STEPS;
  selectedStep = BOOKING_STEPS.OFFER;
  numberOfCompletedSteps = 0;
  paymentObject: any = null;
  psgTitles = [];
  seatsPerSegment = null;
  ssrCode2DescriptionMapping: any = {};
  segment2FlightRouteMapping: any = {};
  passengersInfo = [];
  paymentSettings: PaymentSettings = new PaymentSettings();
  paymentStep = 0;
  seatAvailabilityResponseWarning = [];
  namePattern = /^[a-z ,.'-]+$/i;
  emailPattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  digitsPattern = /^\d+$/;
  fiscalNamePattern = /^[a-zA-Z0-9\s]*$/;
  umbrellaCompanyShow = false;
  selectedCompany = '';
  isChangeCompany = false;
  isSubmit = false;
  includedSpecialServices;
  currentTravellerFromUmbrella;
  isSelectSeatsButtonDisable = true;
  residentsList = Dictionary.ResidentDiscountCodes;
  largeFamilyList = Dictionary.getSearchTypeLargeFamily();

  // corporate profiles
  corporateShow = false;
  selectedCorporate = '';
  isChangeCorporate = false;
  currentTravellerFromProfile;
  travelerProfiles = {};
  loyaltyProgramAccounts = [];

  iberiaDiscounts = {};
  residentDiscounts = Dictionary.ResidentDiscounts;
  showDiscounts = false;
  showDiscountField = {
    residentCode: false,
    largeFamily: false
  };
  requireDocumentId = false;
  isSecureFlight = false;


  fareRules = [];
  changes: {};
  hasChanges: boolean;
  private confirmCallback: () => void;
  seatPassengers: any[] = [];
  selectedServices = [];

  selectedCorporateObj: any = null;
  disableCloseCorporate = false;

  selectedCompanyObj: any = null;
  disableCloseCompany = false;

  telInputObjects = {};
  passengers = [];
  passengerIdx = {};
  totalPrice: any = 0.0;
  servicePerSegment = {
    entireTrip: [],
    perLeg: {},
    perSegment: {}
  };
  segments = [];
  originOfferPrice = 0;
  surchargesInfo = '';

  minMaxDateOfBirth: { [key: string]: MinMaxDate } = {};
  minMaxDocumentExpirationDate: MinMaxDate = new MinMaxDate();

  paymentMethod: string;
  labels = {
    documentType: 'Document Type',
    documentID: 'Document ID',
    expirationDate: 'Expiration date of Document',
    fiscalName: 'Fiscal Name',
    issuingCountryCode: 'Issuing Country Code',
    citizenshipCountryCode: 'Citizenship Country Code',
    residenceCountryCode: 'Residence Country Code',
    birthdate: 'Date of birth',
    email: 'Email',
    title: 'Treatment',
    gender: 'Gender',
    name: 'Given Name',
    surname: 'Surname',
    areaCode: 'Area code',
    phone: 'Phone',
    'phone.countryCode': 'Phone Code',
    'phone.number': 'Phone Number',
    address: 'Address',
    'address.label': 'Label',
    'address.countryCode': 'Country',
    'address.cityName': 'City',
    'address.postalCode': 'Postal Code',
    'address.street': 'Street',
  };

  // remarks
  agencyRemarksData: RemarkDataToUpdate | RemarkTextToUpdate;
  corporateRemarksData: RemarkDataToUpdate | RemarkTextToUpdate;
  mandatoryAgencyRemarks;
  mandatoryCorporateRemarks;
  agencyRemarkTemplates = [];
  corporateRemarkTemplates = [];
  isAgencyRemarksTemplateSelected = false;
  isCorporateRemarksTemplateSelected = false;
  remarksSaveTriggered = 0;

  passengerTypes = PASSENGER_TYPES;
  documentTypeOptions = Dictionary.DocumentTypes;
  dbService: NgxIndexedDBService;
  fillPassengersData = false;
  previousPassengersData = [];
  preset: Preset;
  qualifiers: Qualifier[];
  loyaltyProgramAccountSupportedProviders = LOYALTY_PROGRAM_ACCOUNT_SUPPORTED_PROVIDERS;

  //god mode
  bookingActions: BookingActionsState = new BookingActionsState();
  isFirstStateSaved = false;

  addresses = {
    home: false,
    destination: false,
  };

  //osi
  applyOsiByPassengerTypeDisabled = {};
  selectedPassengersPerOsi = {};

  @HostListener('document:click', ['$event']) clickout({target}) {
    if (target.className !== 'selected-company' &&
      target.className !== 'selected-company-span' &&
      target.className !== 'click-select' &&
      !target.classList.contains('drop-down-input')
    ) {
      this.isChangeCompany = false;
    }
  }

  constructor(private route: ActivatedRoute,
              public ls: LocalStorageService,
              private fb: FormBuilder,
              private applicationRef: ApplicationRef,
              private _notificationSvc: NotificationService,
              public service: NDCApiService,
              public profileService: ProfilesService,
              public helpers: HelpersService,
              public faker: FakerService,
              public meta: MetaService,
              public seatMapService: SeatMapService,
              public paymentService: PaymentModuleService,
              public dataTransferService: DataTransferService,
              public modalService: NgbModal,
              private umbrellaService: UmbrellaService,
              private elementRef: ElementRef,
              private gtmService: GtmService,
              private cdr: ChangeDetectorRef,
              private travelerTypePipe: TravelerTypePipe,
              public dateService: DateService,
              public sentryService: SentryService,
              private router: Router,
              private offerService: OfferService,
              public confirmDialogService: ConfirmDialogService,
              public hubAgenciesService: HubAgenciesService,
              private formsService: FormsService,
              private injector: Injector,
  ) {
    if (!this.helpers.isPrivateMode) {
      this.dbService = <NgxIndexedDBService>this.injector.get(NgxIndexedDBService);
    }
  }

  ngOnInit() {
    this.responseError = '';
    this.showLoader = true;

    this.responseWarning = [];

    this.offerID = this.route.snapshot.paramMap.get('offerID');

    if (!this.offerID) {
      this.helpers.go('/search');
    }

    if (this.ls.profilesType === 'umbrella') {
      this.umbrellaService.checkUmbrellaIsAuth()
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe();
    }

    this.hubAgenciesService.isPresetsLoaded$.subscribe(isLoaded => {
      if (isLoaded) {
        this.handlePresetInUrl();
        this.sendOfferPrice();
      }
    });

    this.dataTransferService.umbrellaLogOut$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        if (this.form) {
          this.resetForm();
        }
      });
    this.redirectToNewUrl();
  }

  sendOfferPrice() {
    let body: any = {
      offerID: this.offerID
    };
    if (this.qualifiers) {
      body.qualifiers = this.qualifiers;
    }
    if (this.preset?.taxExemptions?.length) {
      body.taxExemptions = this.preset.taxExemptions.map(taxExemption => taxExemption.code) || [];
    }
    this.service.sendOfferPrice(body)
      .then((response: any) => {
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, 200, response);
        this.gtmService.addEvent('OfferView');
        this.showLoader = false;

        this.responseWarning = this.helpers.getWarningsFromResponse(response);
        this.getWarningsHeight();

        this.service.price = response.price;
        this.seatAvailabilityEnabled = response.allowedRequests.SeatAvailability;
        this.serviceListEnabled = response.allowedRequests.ServiceList;
        this.orderCreateWithPaymentEnabled = response.allowedRequests.OrderCreateWithPayment;

        this.offer = {
          allowedRequests: response.allowedRequests,
          price: response.price,
          owner: response.owner,
          paymentTimeLimit: response.paymentTimeLimit,
          expiresAt: response.offerExpiration,
          disclosures: response.disclosures,
          shoppingResponseID: response.shoppingResponseID,
          specialServices: response.specialServices,
          specialServicesWithoutIncluded: response.specialServices ?
            this.removeDuplicates(response.specialServices
              .filter(service => !service.desc.match('Included')), 'desc') : [],
          alreadyIncludedSpecialServices: response.specialServices ? response.specialServices
            .filter(service => service.desc.match('Included'))
            .filter((serv, idx, self) => idx === self.findIndex((s) => (s.code === serv.code && s.desc === serv.desc && s.passengerType === serv.passengerType))) : [],
          createdAt: response.createdAt,
          discounts: response.discounts,
          flights: response.flights,
          passengers: response.passengers,
          fareRules: this.offerService.getFareRules(response),
          cardSurchargesMap: response.cardSurchargesMap,
          agencyData: response.agencyData,
          otherOffers: response.otherOffers,
          remarks: response.remarks,
          availableTitles: response.availableTitles,
          allowedExtraFields: response.allowedExtraFields
        };

        this.setFareRulesModel(response.flights);

        this.titles = this.helpers.getTitles(this.offer.availableTitles);
        this.minMaxDocumentExpirationDate.minDate = this.helpers.formatDateToNgbDateStruct(this.offer.flights[0].departure.date);

        if (this.offer.remarks?.templates) {
          ({
            templates: this.agencyRemarkTemplates,
            mandatoryRemarks: this.mandatoryAgencyRemarks
          } = this.getTemplatesAndMandatoryRemarks(this.offer.remarks.templates));
        }

        this.generateRouteInfo();
        this.travelers = {};
        this.offer.passengers.forEach((passenger) => {
          const key = passenger.passengerType.toLowerCase();
          this.travelers[key] = (this.travelers[key] || 0) + 1;
        });
        this.passengerTypes.forEach(psgType => {
          const key = psgType.toLowerCase();
          if (!this.travelers[key]) {
            this.travelers[key] = 0;
          }
        });
        this.service.owner = this.sentryService.owner = response.owner;

        this.originOfferPrice = response.price.consumer.total;
        if (this.offer && this.offer.discounts && Object.keys(this.offer.discounts).length) {
          this.requireDocumentId = true;
        }

        this.refreshTotalPrice();

        if ((this.offer.owner === 'IB' || this.offer.owner === 'VY') && this.offer.discounts) {
          this.showDiscounts = true;
          this.showDiscountField = {
            residentCode: !!this.offer.discounts.residentCode,
            largeFamily: !!this.offer.discounts.largeFamily,
          };
        }

        this.paymentSettings = Object.assign({none: true}, response.allowedPaymentMethods);
        this.ssrCode2DescriptionMapping = {};
        if (response.specialServices) {
          response.specialServices.map(item => {
            this.ssrCode2DescriptionMapping[item.code] = item.desc;
          });
        }

        this.segment2FlightRouteMapping = {};
        this.offer.flights.forEach(flight => {
          // if (!this.isSecureFlight) {
          //   this.isSecureFlight = flight.segments.some(s => s.isSecureFlight);
          // }
          flight.segments.forEach(fs => {
            this.segment2FlightRouteMapping[fs.segmentID] = fs.originDestination.departure.airportCode + '-' + fs.originDestination.arrival.airportCode;
            this.segments.push(fs);
          });
        });

        this.buildForm();

        this.handleCorporateInUrl();
        this.closePaxIncorrectDataPopover();

        this.passengerIdx = {};
        response.passengers.map(p => {
          let len = this.passengers.push(p);
          this.passengerIdx[p.travelerReference] = len - 1;
        });
        this.minMaxDateOfBirth = this.helpers.prepareBirthdateValidation(this.offer, this.preset);
        this.getPreviousPassengersInfo();

        this.checkGodMode();
      })
      .catch((err) => {
        this.responseWarning = this.helpers.getWarningsFromResponse(err);
        this.showLoader = false;
        this.responseError = this.helpers.getError(err);
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, err.status, err);
        if (this.helpers.isCriticalError(err)) {
          throw err;
        }
      });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.shiftKey && (event.ctrlKey || event.altKey) && event.code === 'KeyF' && this.selectedStep === BOOKING_STEPS.PAX) {
      this.fillPassengers();
    }
    if (event.shiftKey && (event.ctrlKey || event.altKey) && event.code === 'KeyF' && this.selectedStep === BOOKING_STEPS.RESERVATION &&
      this.paymentService.form && this.paymentSettings.card) {
      this.fillPayment();
    }
  }

  private handleCorporateInUrl() {
    this.route.queryParams
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        filter(params => params['corporate_id']),
        switchMap(params => {
          if (this.ls.profilesType === 'airgateway') {
            return this.profileService.searchCorporates(params['corporate_id'])
              .pipe(
                filter((res: any) => res.body && res.body.accounts),
                map((res: any) => res.body.accounts),
                filter((account: any) => !!account.length),
                map((account: any) => account[0])
              );
          } else if (this.ls.profilesType === 'umbrella') {
            return this.umbrellaService.getCompanyData(params['corporate_id']);
          }
          return of(null);
        }),
        filter(selectedObj => !!selectedObj)
      )
      .subscribe(selectedObj => {
        if (this.ls.profilesType === 'airgateway') {
          this.selectedCorporateObj = selectedObj;
          if (this.selectedCorporateObj) {
            this.corporateShow = true;
            this.disableCloseCorporate = true;
            this.isChangeCorporate = false;
            this.doSelectCorporate(this.selectedCorporateObj);
          }
        } else if (this.ls.profilesType === 'umbrella') {
          this.selectedCompanyObj = selectedObj;
          if (this.selectedCompanyObj) {
            this.umbrellaCompanyShow = true;
            this.disableCloseCompany = true;
            this.isChangeCompany = false;
            this.selectedCompany = this.selectedCompanyObj.name;
            this.resetForm();
            this.setUmbrellaCompanyData(this.selectedCompanyObj);
          }
        }
      });
  }

  handlePresetInUrl() {
    this.route.queryParams
      .subscribe(params => {
        const presetTitle = params['presetTitle'];
        if (presetTitle) {
          this.preset = this.hubAgenciesService.presets?.find(p => p.title === presetTitle);
          this.qualifiers = this.preset?.qualifier && this.preset.qualifier.type && this.preset.qualifier.code ? [this.preset.qualifier] : null;
        }
      }
    );
  }

  private removeDuplicates(arrObj, prop) {
    return arrObj.filter((obj, pos, arr) => {
      return arr.map((maoObj) => maoObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  private setFareRulesModel(flights: any) {
    this.fareRules = [];
    flights.map((flight) => {
      flight.segments?.map((segment) => {
        const fareRulesByPassengerType =
          segment.detail.classOfService.fareRules?.find(fareRule => fareRule.passengerType === this.offer.passengers[0]?.passengerType);
        const tempData = {
          route: {
            depCode: segment.originDestination.departure.airportCode,
            arrCode: segment.originDestination.arrival.airportCode,
            fareBasisCode: segment.detail && segment.detail.classOfService &&
            segment.detail.classOfService.fare && segment.detail.classOfService.fare.basisCode ? segment.detail.classOfService.fare.basisCode : ''
          },
          rulesByType: {}
        };
        if (fareRulesByPassengerType?.penalties?.length > 0) {
          fareRulesByPassengerType.penalties.map(item => {
            if (item) {
              const tempItem = {
                description: item.description || '-',
                amounts: item.amounts?.length ? [...new Map(item.amounts.filter(am => am.amount > 0)
                  .map(it => [it.amount, it])).values()] : [],
                type: item.type || 'NO SHOW'
              };

              if (tempItem.description !== '-' || tempItem.amounts?.length) {
                if (tempData.rulesByType[tempItem.type]) {
                  tempData.rulesByType[tempItem.type].push(tempItem);
                } else {
                  tempData.rulesByType[tempItem.type] = [tempItem];
                }
              }
            }
          });
        }
        if (Object.keys(tempData.rulesByType)?.length) {
          this.fareRules.push(tempData);
        }
      });
    });
  }

  resetForm() {
    Object.entries(this.passengerDefaults).map(obj => {
      this.form.patchValue({
        [obj[0]]: (<AbstractControl>obj[1]).value,
      });
    });
    this.resetLoyaltyProgramAccount();
  }

  resetLoyaltyProgramAccount() {
    this.loyaltyProgramAccounts = [];
    this.form.get('loyaltyProgramAccount').reset();
  }

  onSelectedCompany(event) {
    let umbrellaCompany = null;
    try {
      if (this.ls.UmbrellaCompany) {
        umbrellaCompany = JSON.parse(this.ls.UmbrellaCompany);
      }
    } catch (error) {
      console.error("Invalid JSON in ls.UmbrellaCompany:", error);
    }
    if (umbrellaCompany && event) {
      this.selectedCompany = umbrellaCompany.text;
      this.umbrellaCompanyShow = false;
      this.isChangeCompany = false;
      this.resetForm();
      setTimeout(() => this.umbrellaCompanyShow = true);
      this.umbrellaService.getCompanyData(umbrellaCompany.id).subscribe(companyData => {
        this.setUmbrellaCompanyData(companyData);
      });
    }
    if (!event) {
      this.selectedCompany = '';
      this.umbrellaCompanyShow = false;
      this.resetForm();
    }
  }

  setUmbrellaCompanyData(companyData) {
    const airlineData = companyData?.data?.memberships?.airline;
    if (airlineData?.length) {
      airlineData.forEach(flight => {
        if (flight.memberNumber) {
          if (flight.memberNumber.startsWith("OIN") && this.offer.owner === 'AF' || this.offer.owner === 'KL') {
            flight.memberNumber = flight.memberNumber.slice(3);
          }
          this.loyaltyProgramAccounts.push(flight.memberNumber);
          const loyaltyProgramAccountControl = this.form.get('loyaltyProgramAccount');
          if (flight.alliance === this.offer.owner && this.isLoyaltyProgramAccountEnabled() && !loyaltyProgramAccountControl?.value) {
            loyaltyProgramAccountControl.setValue(flight.memberNumber);
          }
        }
      });
    }
  }

  onSelectedCorporate(event) {
    let selectedCorporate = {
      name: '',
      id: ''
    };
    if (event) {
      const corporate = JSON.parse(this.ls.profileCorporate);
      this.corporateShow = true;
      this.isChangeCorporate = false;
      this.doSelectCorporate(corporate);
    } else {
      this.selectedCorporate = '';
      this.corporateShow = false;
      this.isChangeCorporate = true;
      this.doSelectCorporate(selectedCorporate);
    }
  }

  private doSelectCorporate(corporate) {
    this.selectedCorporate = corporate.name || corporate.text;
    this.offerInfo['accountId'] = corporate.account_number;
    this.buildForm();
    this.resetForm();
    this.form.patchValue({
      corporateID: corporate.corporate_id,
      loyaltyProgramAccount: corporate.loyalty_program_discount_codes && corporate.loyalty_program_discount_codes[this.offer.owner]
                              ? corporate.loyalty_program_discount_codes[this.offer.owner]
                                : ''
    });
    if (corporate.remarks && corporate.remarks.length) {
      ({
        templates: this.corporateRemarkTemplates,
        mandatoryRemarks: this.mandatoryCorporateRemarks
      } = this.getTemplatesAndMandatoryRemarks(corporate.remarks));
    }
  }

  onSeatSelect($event) {
    this.refreshTotalPrice();
    this.prepareSegmentSeats();
    this.updatePaymentSettings();
  }

  setPhoneCode(control: string, value) {
    this.form.controls[control].get('data').get('phone').get('countryCode').setValue(value);
    this.passengerDefaults[control]?.get('data').get('phone').get('countryCode').setValue(value);
  }

  setPhoneCountryCodeISO(control: string, value: string) {
    this.form.controls[control].get('data').get('phone').get('countryCodeISO').setValue(value);
    this.passengerDefaults[control]?.get('data').get('phone').get('countryCodeISO').setValue(value);
  }

  buildOSI() {
    const osiControl = this.fb.group({
      'osiCode': [''],
      'actionCode': [''],
      'text': [''],
      'passengerRef': [''],
    });

    return osiControl;
  }

  addOSI() {
    const control = this.buildOSI();
    (this.form.get('osi') as FormArray).push(control);
  }

  removeOSI(index: number) {
    const arrayControl = this.form.get('osi') as FormArray;
    arrayControl.removeAt(index);
  }

  getOSI() {
    const osiValueArr = this.form.get('osi').value;

    const osi = osiValueArr.flatMap(({ passengerRef, osiCode, actionCode, text }) => {
      if (passengerRef === 'all') {
        return [{
          osiCode,
          actionCode,
          text,
          passengerRef: ''
        }];
      } else {
        return passengerRef.split(' ').map(ref => ({
          osiCode,
          actionCode,
          text,
          passengerRef: ref
        }));
      }
    });

    return osi;
  }

  onPassengerRefChange(control: FormControl, isChecked: boolean, osiIdx: number, travIdx: number, travTypeIdx?: number, passengerType?: string) {
    let newPassengerRefValue;

    if (travIdx === -1) {
      if (isChecked) {
        newPassengerRefValue = 'all';
        this.applyOsiByPassengerTypeDisabled[osiIdx] = true;
      } else {
        newPassengerRefValue = '';
        this.applyOsiByPassengerTypeDisabled[osiIdx] = false;
      }
    } else {
      if (!this.selectedPassengersPerOsi[osiIdx]) {
        this.selectedPassengersPerOsi[osiIdx] = {};
      }
      if (!this.selectedPassengersPerOsi[osiIdx][travTypeIdx]) {
        this.selectedPassengersPerOsi[osiIdx][travTypeIdx] = {};
      }
      this.selectedPassengersPerOsi[osiIdx][travTypeIdx][travIdx] = isChecked;

      const passengersByType = this.offer.passengers.filter(passenger => passenger.passengerType === passengerType.toUpperCase());
      const passengerRef = passengersByType[travIdx].travelerReference;
      const currentPassengerRefValue = control.get('passengerRef').value;

      if (currentPassengerRefValue.includes(passengerRef)) {
        newPassengerRefValue = currentPassengerRefValue
          .split(' ')
          .filter(ref => ref !== passengerRef)
          .join(' ');
      } else {
        newPassengerRefValue = currentPassengerRefValue
          ? `${currentPassengerRefValue} ${passengerRef}`
          : passengerRef;
      }
    }

    control.get('passengerRef').setValue(newPassengerRefValue);
  }

  // Build form and helper functions below
  private buildForm() {
    this.form = this.fb.group({
      corporateID: '',
      loyaltyProgramAccount: '',
      osi: this.fb.array([]),
    });
    this.iberiaDiscounts = {};

    this.seatMapService.maxAllowedSeats = this.offer.passengers.filter(psg => psg.passengerType !== 'INF').length;
    let isFirstPassenger = true;
    const passengersByType = [];
    for (const [key, value] of Object.entries(this.travelers)) {
      passengersByType.push({[key]: value});
    }

    passengersByType.map(passenger => {
      let counter = 0;
      const [passengerKey] = Object.keys(passenger);
      for (let i = 0; i < passenger[passengerKey]; i++) {
        const passengerType = `passenger_${passengerKey}_` + counter;
        const control = this.makePassengerGroup(passengerKey.toUpperCase(), isFirstPassenger, passengerType, counter);
        const defaultControl = this.makePassengerGroup(passengerKey.toUpperCase(), isFirstPassenger, passengerType, counter);
        this.form.addControl(passengerType, control);
        this.passengerDefaults[passengerType] = defaultControl;
        isFirstPassenger = false;

        if (passengerKey.toUpperCase() === 'INF') {
          this.adultReferences[counter] = this.offer.passengers[counter].travelerReference;
        }

        if (!this.firstTravelerID) {
          this.firstTravelerID = passengerType;
          this.ssrPassengerID = this.firstTravelerID;
        }
        this.ssrSelected[passengerType] = {};
        this.ssrSelectedText[passengerType] = {};
        this.ssrSelectedValidation[passengerType] = {};
        this.ssrSelectedRequiredText[passengerType] = {};

        this.isGenderExists[passengerType] = true;

        // prepare errors for ib discounts
        this.iberiaDiscounts[passengerType] = {
          residentCode: {
            identityDocumentNumber: '',
            identityDocumentType: this.offer.discounts && this.offer.discounts.residentCode ? this.offer.discounts.residentCode : '',
            remark: '',
          },
          largeFamily: {
            identityDocumentNumber: '',
            identityDocumentType: this.offer.discounts && this.offer.discounts.largeFamily ? this.offer.discounts.largeFamily : '',
            remark: '',
          },
        };

        counter++;
      }
      return passenger;
    });
  }

  onSelectFlyerNumber(psgKey, airline, number) {
    this.form.controls[psgKey].patchValue({
      passenger: {
        fqtvInfo: {
          account: {
            number: number,
          },
          airlineID: airline
        }
      }
    });
  }

  onEmitUmbrellaPsgFormData(event, psgType) {
    this.isPatchForm = true;
    let psgKey = event.passengerKey;
    if (!event.responseData) {
      this.currentTravellerFromUmbrella = undefined;
      return this.resetPassenger(psgKey);
    }
    const resData = event.responseData;

    if (resData.data) {
      const dateArray = resData.data.generalData.birthdate ? resData.data.generalData.birthdate.split('.') : [];
      const birthDateObj = dateArray.length === 3 ?
        HelpersService.getStructFromDate(new Date(`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`), false) :
        null;
      const expirationDateArray = resData.data.papers.passports.length && resData.data.papers.passports[0].expiration ?
        resData.data.papers.passports[0].expiration.split('.') :
        [];
      const expirationDateObj = expirationDateArray.length > 2 ? HelpersService.getStructFromDate(new Date(`${expirationDateArray[2]}-${expirationDateArray[1]}-${expirationDateArray[0]}`), false) : null;
      let fqtvNum = {number: '', airline: this.offer.owner};
      if (resData.data.memberships.flight.length) {
        resData.data.memberships.flight.map(flight => {
          if (flight.alliance === this.offer.owner) {
            fqtvNum.number = flight.memberNumber;
          }
        });
        if (!fqtvNum.number) {
          this.currentTravellerFromUmbrella = event;
        }
      }

      let phone = resData.data.generalData.mobilePhone || resData.data.generalData.privatePhone || resData.data.generalData.businessPhone;
      const phoneNumber = parsePhoneNumberFromString(phone, resData.data.generalData.nationality);
      let countryCode = '';
      let phoneNum = '';
      let country = '';
      if (phoneNumber && phoneNumber.isValid()) {
        countryCode = phoneNumber.countryCallingCode.toString();
        phoneNum = phoneNumber.nationalNumber.toString();
        country = phoneNumber.country;
      } else {
        countryCode = country = resData.data.generalData.nationality || this.ls.settings?.telCountryNumber?.iso
          || this.telInputObjects[psgKey]?.s.iso2 || 'es';
      }

      const titleAndGenderData = this.getTitleAngGenderByUmbrellaResponse(resData.data.generalData, psgKey);

      let tempPassengerData = {
        'documents': [{
          'documentType': (this.documentOptions[1].value && resData.data.papers.passports.length) ? this.documentOptions[1].value : '',
          'documentID': resData.data.papers.passports.length ? resData.data.papers.passports[0].number : '',
          'expirationDate': expirationDateObj ? expirationDateObj : null,
          'issuingCountryCode': resData.data.papers.passports.length ? resData.data.papers.passports[0].issueCountry : ''
        }],
        'data': {
          'passengerType': psgType || '',
          'birthdate': birthDateObj || null,
          'email': resData.email || '',
          'fqtvInfo': {
            'account': {
              'number': fqtvNum.number,
            },
            'airlineID': fqtvNum.airline,
          },
          'title': titleAndGenderData.title,
          'gender': titleAndGenderData.gender,
          'name': resData.firstname,
          'surname': resData && resData.name ? resData.name : '',
          'phone': {
            'areaCode': '',
            'countryCode': countryCode,
            'number': phoneNum,
          },
          'address': {
            'countryCode': resData.data.papers.passports.length ? resData.data.papers.passports[0].country : '',
            'cityName': '',
            'postalCode': '',
            'street': '',
          },
        },
      };

      if (country && this.telInputObjects[psgKey]) {
        this.telInputObjects[psgKey].setCountry(country.toLowerCase());
      }

      if (tempPassengerData.documents[0].documentType) {
        this.addDocument(psgKey);
      }

      if (psgKey && this.form.controls[psgKey] !== undefined) {
        this.resetPassenger(psgKey);
        setTimeout(() => {
          this.form.controls[psgKey].patchValue(tempPassengerData);
        });
      }
    } else {
      this.isGenderExists[psgKey] = false;
    }
  }

  getTitleAngGenderByUmbrellaResponse(generalData, psgKey) {
    let title = '';
    let gender = '';

    const availableTitle = this.offer.availableTitles?.find(t => t[generalData.greeting?.commonTitle]) || this.offer.availableTitles?.find(t => Object.values(t).includes(generalData.sex?.toLowerCase()));
    if (availableTitle) {
      title = Object.keys(availableTitle)[0];
      gender = this.helpers.capitalizeFirstLetter(Object.values(availableTitle)[0]);
    }

    this.isGenderExists[psgKey] = !!gender;

    return {title, gender};
  }

  onEmitProfilePsgFormData(event, psgType) {
    this.isPatchForm = true;
    let passKey = event.passengerKey;
    if (!event.response) {
      this.currentTravellerFromProfile = undefined;
      delete this.travelerProfiles[passKey];
      return this.resetPassenger(passKey);
    }

    const data = event.response.profile;

    this.isGenderExists[passKey] = data.gender;

    const dateArray = data.birthdate.split('.');
    const birthDateObj = data.birthdate ? HelpersService.getStructFromDate(new Date(`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`), false) : null;
    let fqtvNum = {number: '', airline: ''};
    if (data.frequent_flyer_numbers && data.frequent_flyer_numbers.length) {
      data.frequent_flyer_numbers.map(ffn => {
        if (ffn.alliance === this.offer.owner) {
          fqtvNum.number = ffn.member_number;
          fqtvNum.airline = ffn.alliance;
        }
      });
      if (!fqtvNum.number) {
        // data.passengerKey = event.passengerKey;
        this.currentTravellerFromProfile = data;
      }
    }

    let phone = data.phone_code + data.phone_number;
    const phoneNumber = parsePhoneNumberFromString('+' + phone.replace(/\+/g, ''));
    let countryCode = '';
    let phoneNum = '';
    let countryCodeISO = data.phone_country_code || this.ls.settings?.telCountryNumber?.iso ||
      this.telInputObjects[passKey]?.s.iso2 || 'es';
    if (phoneNumber && phoneNumber.isValid()) {
      countryCode = phoneNumber.countryCallingCode.toString();
      phoneNum = phoneNumber.nationalNumber.toString();
    } else {
      countryCode = data.phone_code || this.ls.settings?.telCountryNumber?.dialCode
        || this.telInputObjects[passKey]?.s.dialCode || '34';
      phoneNum = data.phone_number;
    }

    if (data.documents?.length) {
      data.documents.forEach(document => {
        this.addDocument(passKey);

        const {
          id, type, expiration_date, fiscal_name, issuing_country_code, citizenship_country_code, residence_country_code
        } = document;

        const modifiedDocument = {
          documentID: id,
          documentType: type === 'PT' ? 'PP' : type,
          expirationDate: expiration_date ? HelpersService.getStructFromDate(new Date(expiration_date)) : null,
          fiscalName: fiscal_name,
          issuingCountryCode: issuing_country_code,
          citizenshipCountryCode: citizenship_country_code,
          residenceCountryCode: residence_country_code,
        };

        Object.assign(document, modifiedDocument);
      });
    }

    let psgData = {
      'documents': data.documents,
      'data': {
        'passengerType': psgType || '',
        'birthdate': birthDateObj ? birthDateObj : null,
        'email': data.email ? data.email : '',
        'fqtvInfo': {
          'account': {
            'number': fqtvNum.number,
          },
          'airlineID': fqtvNum.airline,
        },
        'title': data.title,
        'gender': data.gender,
        'name': data.name,
        'surname': data.surname,
        'phone': {
          'areaCode': '',
          'countryCode': countryCode,
          'number': phoneNum,
          'countryCodeISO': countryCodeISO,
        },
        'address': {
          'countryCode': data.address_country_code,
          'cityName': data.address_city_name,
          'postalCode': data.address_postal_code,
          'street': data.address_street,
        },
        'storeProfile': true,
      },
    };

    if (Object.values(psgData.data.address).some(value => !!value)) {
      this.addresses.home = true;
    }

    if (countryCodeISO && this.telInputObjects[passKey]) {
      this.telInputObjects[passKey].setCountry(countryCodeISO);
    }

    if (passKey && this.form.controls[passKey] !== undefined) {
      this.resetPassenger(passKey);
      this.travelerProfiles[passKey] = psgData;
      setTimeout(() => this.form.controls[passKey].patchValue(psgData));
    }
  }

  private resetPassenger(passKey: string) {
    if (passKey && this.passengerDefaults[passKey] !== undefined) {
      this.form.patchValue({
        [passKey]: (<AbstractControl>this.passengerDefaults[passKey]).value,
      });
    }
  }

  hasStoreProfileEnabled() {
    const passengersByType = [
      {adt: this.travelers.adt},
      {yad: this.travelers.yad},
      {chd: this.travelers.chd},
      {inf: this.travelers.inf}
    ];
    let hasStoreProfile = false;
    passengersByType.map(passenger => {
      let counter = 0;
      const [passengerKey] = Object.keys(passenger);
      for (let i = 0; i < passenger[passengerKey]; i++) {
        let id = `passenger_${passengerKey}_${counter}`;
        const sp = this.form.get(id + '.data.storeProfile').value;
        if (sp) {
          hasStoreProfile = true;
          break;
        }
        counter++;
      }
    });

    return hasStoreProfile;
  }

  onChangeInputValue(event, ctrlName, passengerKey) {
    this.currentTravellerFromUmbrella = undefined;
    this.currentTravellerFromProfile = undefined;
    // this.resetPassenger(passengerKey);
    if (passengerKey) {
      (<FormGroup>(<FormGroup>this.form.controls[passengerKey]).controls['data']).controls[ctrlName].patchValue(event);
    }
  }

  onChangeInputValueFromProfile(event, ctrlName, passengerKey) {
    this.currentTravellerFromUmbrella = undefined;
    this.currentTravellerFromProfile = undefined;
    // this.form.controls[passengerKey].reset();
    (<FormGroup>(<FormGroup>this.form.controls[passengerKey]).controls['data']).controls[ctrlName].patchValue(event);
  }

  private makePassengerGroup(type: string, isFirstPassenger: boolean, passengerType: string, counter: number): FormGroup {
    let form: FormGroup = this.fb.group({
      'documents': this.fb.array(this.requireDocumentId ? [this.buildDocument()] : []),
      'passengerType': [type],
      'adultReference': [type === 'INF' ? this.offer.passengers[counter].travelerReference : ''],
      'data': this.fb.group({
        'birthdate': [null],
        'email': [''],
        'fqtvInfo': this.fb.group({
          'account': this.fb.group({
            'number': [''],
          }),
          'airlineID': [''],
        }),
        'title': [''],
        'gender': [''],
        'name': [''],
        'surname': [''],
        'phone': this.fb.group({
          'areaCode': [''],
          'countryCode': [this.telInputObjects[passengerType] ? this.telInputObjects[passengerType].s.dialCode : ''],
          'number': [''],
          'countryCodeISO':  [this.telInputObjects[passengerType] ? this.telInputObjects[passengerType].s.iso2 : '']
        }),
        'storeProfile': [true],
        'address': this.buildAddress('addressAtHome'),
        'addresses': this.fb.array([]),
        'addressType': ['']
      }),
      'metas': [{}],
    });

    if (this.showDiscounts) {
      form = this.addIberiaDiscounts(form, passengerType);
    }

    let airlineId  = form.get('data.fqtvInfo.airlineID');
    let frequentFlyerNumber = form.get('data.fqtvInfo.account.number');

    // frequentFlyerNumber.valueChanges.subscribe((value: string) => {
    //   if (value) {
    //     airlineId.setValidators([Validators.required]);
    //   } else {
    //     airlineId.clearValidators();
    //   }
    //   airlineId.updateValueAndValidity({emitEvent: false, onlySelf: false});
    // });
    //
    // airlineId.valueChanges.subscribe((value: string) => {
    //   if (value) {
    //     frequentFlyerNumber.setValidators([Validators.required]);
    //   } else {
    //     frequentFlyerNumber.clearValidators();
    //   }
    //   frequentFlyerNumber.updateValueAndValidity({emitEvent: false, onlySelf: false});
    // });

    if (!isFirstPassenger && !this.corporateShow) {
      let p = form.get('data');
      let phone = p.get('phone');
      let addr = p.get('address');

      p.get('email').clearValidators();
      phone.get('countryCode').clearValidators();
      phone.get('number').clearValidators();
      // addr.get('postalCode').clearValidators();
      // addr.get('street').clearValidators();
    }

    return form;
  }

  private addIberiaDiscounts(form: FormGroup, passengerType: string): FormGroup {
    if (this.offer.discounts.residentCode) {
      //set residence discount fields
      let residenceDiscountIberia: FormGroup = this.fb.group({
        'documentIdResident': [''],
        'documentTypeResident': ['']
      });
      form.addControl('residenceDiscountIberia', residenceDiscountIberia);

      let documentIdResiden = form.get('residenceDiscountIberia.documentIdResident');
      documentIdResiden.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((value: string) => {
        this.iberiaDiscounts[passengerType].residentCode.identityDocumentNumber = value;
      });

      let documentTypeResident = form.get('residenceDiscountIberia.documentTypeResident');
      documentTypeResident.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((value: string) => {
        this.iberiaDiscounts[passengerType].residentCode.remark = value;
      });
    }

    if (this.offer.discounts.largeFamily) {
      //set large family discount fields
      let largeFamilyDiscountIberia: FormGroup = this.fb.group({
        'documentIdFamily': [''],
        'documentTypeFamily': ['']
      });
      form.addControl('largeFamilyDiscountIberia', largeFamilyDiscountIberia);

      let documentIdFamily = form.get('largeFamilyDiscountIberia.documentIdFamily');
      documentIdFamily.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((value: string) => {
        this.iberiaDiscounts[passengerType].largeFamily.identityDocumentNumber = value;
      });

      let documentTypeFamily = form.get('largeFamilyDiscountIberia.documentTypeFamily');
      documentTypeFamily.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((value: string) => {
        this.iberiaDiscounts[passengerType].largeFamily.remark = value;
      });
    }
    return form;
  }

  fillPassengers() {
    Object.keys(this.form['controls']).map((key) => {
      if (key.startsWith('passenger')) {
        let control = this.form.controls[key];
        const documentsArray = control.get('documents') as FormArray;
        if (!documentsArray.controls.length) {
          this.addDocument(key);
        }
        let randomPsg = this.faker.getRandomPassenger(control.value.passengerType, this.offer);
        control.patchValue(randomPsg);
        let contactDetails = this.faker.getRandomContactDetails(randomPsg);
        control.patchValue(contactDetails);
        if (this.telInputObjects[key]) {
          this.telInputObjects[key].setCountry('es');
          const phoneNumber = this.form.controls[key].get('data').get('phone').get('number');
          if (phoneNumber.invalid) {
            this.fillPassengers();
          }
        }
      }
    });
  }

  fillPayment() {
    this.paymentService.fillPaymentData(this.service.owner, this.paymentService.form);
  }

  validate(): boolean {
    this.errors = {};

    if (!this.form) {
      this.errors['pax_form'] = 'Form doesn\'t exits';
      return false;
    }

    this.validateTriggered = true;

    if (!this.form.valid) {
      for (let key in this.form.value) {
        let control = this.form.get(key);
        if (typeof (control.value) === 'object') {
          let errors = this.formsService.validateObject(control);
          for (let k in errors) {
            this.errors[key] = errors;
          }
        } else {
          if (!control.valid) {
            this.errors[key] = control.errors;
          }
          control.updateValueAndValidity();
          control.markAsDirty();
        }
      }
      this.stepSelect(BOOKING_STEPS.PAX);
      return false;
    }

    return true;
  }

  prepareCardPayment(payment) {
    payment.expiration = payment.expiration_month.toString() + payment.expiration_year.toString();
    delete payment.expiration_month;
    delete payment.expiration_year;
  }

  makeOrder() {
    this.clearPopupNotifications();
    if (!this.validate()) {
      console.log(this.errors);
      return;
    }

    this.bookingProcess.isProcess = true;
    this.bookingHasError = false;
    this.orderCreateError = '';
    this.bookingResponseWarning = [];

    let request: any = {
      passengers: [],
    };
    if (this.paymentObject !== null) {
      request.payment = {...this.paymentObject};

      if (this.paymentObject.method === 'agencyCard' || this.paymentObject.method === 'agencyCash') {
        request.payment = {
          method: this.paymentObject.method,
        };
      }
    }

    let pairsPipe = new PairsPipe();

    request.corporateID = this.form.get('corporateID').value;
    request.loyaltyProgramAccount = this.form.get('loyaltyProgramAccount').value;

    const passengers = this.getPassengersInfo();
    const osi = this.getOSI();

    switch (this.ls.appVersion) {
      case 'v1.2':
        request.passengers = passengers.map(psg => {
          let newPsg = {...psg};
          newPsg.data.phone = convertPhoneIntoString(psg.data.phone);
          return newPsg;
        });
        if (request.payment && request.payment.phone) {
          request.payment.phone = convertPhoneIntoString(request.payment.phone);
        }
        break;
    }

    let metas = this.getMetas();

    let servicesCount = 0;

    if (this.selectedServices.length) {
      request.passengers
        .map((p, idx) => {
          let travelerRef = this.passengers[idx].travelerReference;
          let services = this.selectedServices
            .filter(s => {
              return s.travelerReferences === travelerRef;
            })
            .map(item => {
              item = this.helpers.updateService(item);
              let srv: any = {
                serviceID: item.serviceID,
                segmentReference: item.segmentReferences,
                type: 'service',
                action: 'Create',
              };
              if (item.text) {
                srv.text = item.text;
              }
              return srv;
            });
          servicesCount += services.length;
          request.passengers[idx].services = services;
        });
    }

    let seatsCount = 0;
    this.segments.forEach((seg) => {
      let segmentID = seg.segmentID;
      if (this.seatMapService.selectedSeats[segmentID]) {
        pairsPipe.transform(this.seatMapService.selectedSeats[segmentID]).map((seatObject) => {
          const psgIdx = this.offer.passengers.findIndex(i => i.travelerReference === seatObject[1]);
          if (!!!request.passengers[psgIdx].seats) {
            request.passengers[psgIdx].seats = [];
          }
          let seat = this.seatMapService.allSeats[segmentID][seatObject[0]];
          let seatForRequest = {
            location: seat.location,
            segment: seat.segment,
          };
          request.passengers[psgIdx].seats.push(seatForRequest);
          seatsCount++;
        });
      }
    });

    let offerPassengers = [...this.offer.passengers];
    request.passengers.forEach(reqPassenger => {
      reqPassenger.documents?.forEach(document => {
        if (document.documentType === 'RUT_CO') {
          document.documentType = 'RUT';
        }
      });

      let found = false;
      delete reqPassenger.passengerID;
      offerPassengers.map((p, idx) => {
        let psgType = reqPassenger.passengerType;
        if (!found && (p.passengerType === this.travelerTypePipe.transform(psgType).toUpperCase() || p.passengerType === psgType)) {
          reqPassenger.travelerReference = p.travelerReference;
          offerPassengers.splice(idx, 1);
          found = true;
          if (reqPassenger.adultReference) {
            const adt = request.passengers.find(p => p.travelerReference === reqPassenger.adultReference);
            if (adt) {
              adt.infantReference = reqPassenger.travelerReference;
            }
          }
          return;
        }
      });
      delete reqPassenger.adultReference;
    });

    if (!request.payment && !this.paymentSettings.none) {
      this.orderCreateError = 'Error: Request without provided payment is not allowed';
      this.bookingHasError = true;
      this.bookingProcess.isProcess = false;

      return;
    }

    let remarks: RemarkDataToUpdate | RemarkTextToUpdate;

    if (this.agencyRemarksData && this.corporateRemarksData) {
      remarks = { ...this.agencyRemarksData };
      const corporateRemarksComment = '#------- CORPORATE REMARKS --------\n';
      remarks.result += corporateRemarksComment + this.corporateRemarksData.result;
    } else {
      remarks = this.agencyRemarksData || this.corporateRemarksData;
    }

    this.applicationRef.tick();
    window.scrollTo(0, document.body.scrollHeight);

    const body = {
      shoppingResponseID: this.offer.shoppingResponseID,
      passengers: request.passengers,
      corporateID: request.corporateID,
      loyaltyProgramAccount: request.loyaltyProgramAccount,
      metas,
      payment: request.payment
    };

    this.service.sendOrderCreate(this.offer.shoppingResponseID, request.passengers, request.payment, request.corporateID, request.loyaltyProgramAccount, metas, remarks, this.qualifiers, osi)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(response => {
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, 200, response);

        if (response.warnings) {
          let warningsInfo = {
            id: response.id,
            warnings: response.warnings
          };
          this.ls.orderWarnings = JSON.stringify(warningsInfo);
        }
        this.gtmService.addEvent('OrderCreate');
        if (request.payment) {
          this.gtmService.addEvent('OrderIssue');
          this.meta.setMetadata('OrderIssue', response);
          this.helpers.isOrderTicketed = true;
        } else {
          this.meta.setMetadata('OrderCreateHold', response);
        }

        this.bookingResponseWarning = this.helpers.getRefactoredWarnings(response.warnings);

        this.orderCreateSuccess = 'Booking has been completed. Your Order ID: ' + response.id;
        if (!response.externalOrderID) {
          return;
        }

        let cb = () => {
          this.helpers.go(`/orders/${response.id}?completed=1`);
        };
        this.confirmCallback = cb;
        this.loadConfirmStoreModal();

      }, error => {
        this.bookingHasError = true;
        this.bookingProcess.isProcess = false;
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, error.status, error);
        if (this.helpers.isCriticalError(error)) {
          throw error;
        }
      });
  }

  prepareSegmentSeats() {
    let seatsPerSegment = null;
    let pairsPipe = new PairsPipe();

    this.segments.forEach((seg) => {
      let segmentID = seg.segmentID;
      if (this.seatMapService.selectedSeats[segmentID]) {
        pairsPipe.transform(this.seatMapService.selectedSeats[segmentID]).map((seatObject) => {
          if (seatObject[1]) {
            if (!seatsPerSegment) {
              seatsPerSegment = {};
            }
            if (!seatsPerSegment[segmentID]) {
              seatsPerSegment[segmentID] = {
                name: seg.originDestination.departure.airportCode + ' - ' + seg.originDestination.arrival.airportCode,
                seats: [],
              };
            }
            let seat = this.seatMapService.allSeats[segmentID][seatObject[0]];
            let seatForRequest = {
              location: seat.location,
              segment: seat.segment,
            };

            seatsPerSegment[segmentID].seats.push(seatForRequest);
          }
        });
      }
    });
    this.seatsPerSegment = seatsPerSegment;
    this.isSelectSeatsButtonDisable = this.checkSelectedSeats();
  }

  checkSelectedSeats() {
    const {adt, yad, chd} = Object.assign(this.travelers);
    const passengerCount = adt + (yad === undefined ? 0 : yad) + chd;
    let hasNotFullSeatsForSegment = false;
    let hasFullSeatsForSegment = false;
    const countKeys = Object.keys(this.seatMapService.selectedSeats).filter(f => f.match('count'));
    countKeys.map(key => {
      if (!hasFullSeatsForSegment && +this.seatMapService.selectedSeats[key] !== 0 && +this.seatMapService.selectedSeats[key] === passengerCount) {
        hasFullSeatsForSegment = true;
      }
      if (+this.seatMapService.selectedSeats[key] !== 0 && +this.seatMapService.selectedSeats[key] !== passengerCount) {
        hasNotFullSeatsForSegment = true;
      }
    });
    return !(!hasNotFullSeatsForSegment && hasFullSeatsForSegment);
  }

  showSelectedSeats() {
    this.showSeats = this.seatsPerSegment;
    if (this.showSeats) {
      Object.keys(this.showSeats).map(key => {
        this.showSeats[key].seats.map((item, index) => {
          this.showSeats[key].seats[index].price = this.getSeatPrice(key, item);
        });
      });
    }
    this.refreshTotalPrice();
  }

  onRemoveSeat(event) {
    this.removeSeat(event[0], event[1]);
    this.showSeats = this.seatsPerSegment;
  }

  updatePaymentSettings() {
    if (this.offer.owner === 'AA') {
      this.paymentSettings.agencyCash = !!this.isSelectSeatsButtonDisable;
    }
  }

  getPassengersInfo() {
    this.isSubmit = true;
    let passengers = [];
    let psgTitles = [];
    this.includedSpecialServices = {
      'ADT': [],
      'YAD': [],
      'CHD': [],
      'INF': [],
    };

    /** add included services for passengers */
    if (this.offer.alreadyIncludedSpecialServices.length) {
      this.offer.alreadyIncludedSpecialServices.map(service => {
        const tempService = {
          code: service.code,
          desc: service.desc,
          segmentIDs: [this.offer.flights[0].segments[0].segmentID],
        };
        if (service.passengerType) {
          switch (service.passengerType.toUpperCase()) {
            case 'ADT' || 'VFR':
              this.includedSpecialServices['ADT'].push(tempService);
              return;
            case 'YAD' || 'V14':
              this.includedSpecialServices['YAD'].push(tempService);
              return;
            case 'CHD' || 'VNN':
              this.includedSpecialServices['CHD'].push(tempService);
              return;
            case 'INF' || 'VNF':
              this.includedSpecialServices['INF'].push(tempService);
              return;
          }
        }
      });
    }

    const passengersByType = [];
    for (const [key, value] of Object.entries(this.travelers)) {
      passengersByType.push({[key]: value});
    }

    passengersByType.map(passenger => {
      let counter = 0;
      const [passengerKey] = Object.keys(passenger);
      for (let i = 0; i < passenger[passengerKey]; i++) {
        let id = `passenger_${passengerKey}_${counter}`;
        let passengerObject = JSON.parse(JSON.stringify(this.form.controls[id].value));

        passengerObject.passengerID = id;

        if (passengerObject.data.birthdate) {
          passengerObject.data.birthdate = HelpersService.getFormattedDate(passengerObject.data.birthdate);
        }

        if (passengerObject.documents.length) {
          passengerObject.documents.map(document => document.expirationDate = HelpersService.getFormattedDate(document.expirationDate));
        } else {
          passengerObject.documents = null;
        }
        if (passengerObject.data.fqtvInfo?.airlineID === 'BA') {
          passengerObject.data.fqtvInfo.airlineID = this.offer.owner;
        } else if (passengerObject.data.fqtvInfo?.airlineID === 'FB2') { // FB is used for 'Bulgaria Air', but we also have to send FB for 'Flying Blue (AirFrance, KLM)'
          const availableProviders = ['AF', 'KL'];
          if (availableProviders.includes(this.offer.owner)) {
            passengerObject.data.fqtvInfo.airlineID = 'FB';
          }
        }

        if (passengerObject.data.hasOwnProperty('addressType')) {
          delete passengerObject.data.addressType;
        }

        let discountPreferences = [];
        let ibDiscounts = Object.entries(this.iberiaDiscounts[id]);
        if (ibDiscounts.length && this.showDiscounts) {
          discountPreferences = [];
          ibDiscounts.map(dsc => {
            let key, data;
            [key, data] = dsc;
            if (data.identityDocumentType) {
              discountPreferences.push(data);
            }
          });
          if (discountPreferences.length) {
            passengerObject.identity4Discount = discountPreferences;
          }
        }

        // put selected specialServices for each passenger
        passengerObject.specialServices = [];

        if (this.ssrSelectedCount[id]) {
          let ssrCodes = Object.keys(this.ssrSelected[id]);
          ssrCodes.map((key) => {
            let arr = key.split('|');
            let code = arr[0];
            let segmentID = arr[1];

            let wasAdded = false;
            passengerObject.specialServices.map((item, idx) => {
              if (!wasAdded && item.code === code) {
                wasAdded = true;

                passengerObject.specialServices[idx].segmentIDs.push(segmentID);
              }
            });

            if (!wasAdded) {
              passengerObject.specialServices.push({
                code: code,
                desc: this.ssrSelectedText[id][key],
                segmentIDs: [segmentID],
              });
            }

          });
        }

        psgTitles.push('Passenger ' + passengerKey.toUpperCase() + ' ' + (counter + 1));

        passengers.push(passengerObject);
        counter++;
      }
      return passenger;
    });

    this.psgTitles = psgTitles;
    return passengers;
  }

  clearPopupNotifications() {
    this._notificationSvc.clearNotifications();
  }

  loadSeatAvailability(modal) {
    this.preparePassengersForSeats(this.passengersInfo);

    this.clearPopupNotifications();
    this.seatAvailabilityResponseWarning = [];
    this.open(modal);

    if (this.seatAvailability) {
      return;
    }

    this.seatMapService.showSeatAvailabilityLoader = true;
    this.seatMapService.seatAvailabilityError = {};

    let body = {shoppingResponseID: this.offer.shoppingResponseID};

    this.service.sendSeatAvailability(body)
      .then((response) => {
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, 200, response);
        this.seatMapService.showSeatAvailabilityLoader = false;
        this.seatAvailabilityResponseWarning = this.helpers.getWarningsFromResponse(response);
        this.seatsSegments = response && response.segments ? response.segments : null;
        this.seatAvailability = response;
        this.seatMapService.prepareSeatAvailability(response);
      })
      .catch((response: HttpErrorResponse) => {
        this.seatMapService.showSeatAvailabilityLoader = false;
        this.seatMapService.seatAvailabilityError = this.helpers.getError(response);
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, response.status, response.error);
        if (this.helpers.isCriticalError(response)) {
          throw response;
        }
      });
  }

  open(content, size: any = 'lg', windowClass = '') {
    this.clearPopupNotifications();
    this.modalService.open(content, {
      size, windowClass
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  openServices(title, modal) {
    this.ssrPassengerTitle = title;
    this.modalService.open(modal, {
      size: 'lg', backdrop: 'static'
    }).result.then(() => {}, () => {});
  }

  selectService(service, segmentID) {
    let key = service.code + '|' + segmentID;
    if (this.ssrSelected[this.ssrPassengerID][key]) {
      delete this.ssrSelected[this.ssrPassengerID][key];
      this.ssrSelectedText[this.ssrPassengerID][key] = '';
      delete this.ssrSelectedRequiredText[this.ssrPassengerID][key];
      this.ssrSelectedRequiredText[this.ssrPassengerID][key] = '';
      this.validateSsrServicesModal(key);
    } else {
      this.ssrSelected[this.ssrPassengerID][key] = true;
      if (service.bookingInstructions.mandatoryText === 'mandatory') {
        this.ssrSelectedRequiredText[this.ssrPassengerID][key] = true;
      }
    }
    this.ssrSelectedCount[this.ssrPassengerID] = Object.keys(this.ssrSelected[this.ssrPassengerID]).length;
  }

  saveServices(c) {
    let errorPerService = {}, keys = Object.keys(this.ssrSelected[this.ssrPassengerID]);
    let requiredKeysOfCurrentPassenger = this.ssrSelectedRequiredText[this.ssrPassengerID];
    if (keys) {
      keys.forEach(key => {
        if (requiredKeysOfCurrentPassenger[key] && !this.ssrSelectedText[this.ssrPassengerID][key]) {
          if (!errorPerService[this.ssrPassengerID]) {
            errorPerService[this.ssrPassengerID] = {};
          }
          errorPerService[this.ssrPassengerID][key] = true;
        }
      });
    }
    if (Object.keys(errorPerService).length) {
      this.ssrSelectedValidation = errorPerService;
      this.ssrErrorMessage = 'Please, fill all of the required comments';
      return;
    }
    this.ssrErrorMessage = '';
    this.passengersInfo = this.getPassengersInfo();
    c('ok');
  }

  changeServiceCommentText(service, segmentID) {
    let key = service.code + '|' + segmentID;
    this.validateSsrServicesModal(key);
  }

  validateSsrServicesModal(key: string) {
    if (this.ssrSelectedValidation && this.ssrSelectedValidation[this.ssrPassengerID]) {
      if (this.ssrSelectedValidation[this.ssrPassengerID][key]) {
        delete this.ssrSelectedValidation[this.ssrPassengerID][key];
      }
      if (!Object.keys(this.ssrSelectedValidation[this.ssrPassengerID]).length) {
        this.ssrErrorMessage = '';
      }
    }
  }

  passengerSelected(event: any) {
    this.ssrPassengerID = event.nextId;
  }

  changePayment() {
    if (!this.paymentService.form) {
      this.paymentService.buildForm();
    }
    this.open(this.paymentFormModal);
  }

  proceedPayment(c: any) {
    if (!this.validatePayment()) {
      return false;
    }
    if (this.paymentStep === 0) {
      this.paymentStep = 1;
    } else {
      this.onStepBack(c);
    }
  }

  onStepBack(c: any) {
    this.savePayment();
    this.paymentStep = 0;
    c('ok');
    this.cdr.detectChanges();
  }

  validatePayment() {
    this.paymentValidateTriggered = true;
    return this.paymentService.form.valid;
  }

  savePayment() {
    this.paymentObject = this.paymentService.getPaymentData();

    if (this.isEnsurePayment) {
      this.isEnsurePayment = false;
      this.makeOrder();
    }

    try {
      const cardSurcharges = this.offer.cardSurchargesMap[this.paymentObject.cardCode][this.paymentObject.surchargeType];
      if (cardSurcharges) {
        this.surchargesInfo = `${cardSurcharges.consumer.total} ${cardSurcharges.consumer.currency}`;
      }
    } catch (e) {
      this.surchargesInfo = '';
    }

    return true;
  }

  generateRouteInfo() {
    const routes = [];
    this.offer.flights.map(flight => {
      const route = flight.departure.airportCode + ' → ' + flight.arrival.airportCode;
      routes.push(route);
    });
    this.routeInfo = routes.join(', ');
  }

  getOfferClassCodes() {
    if (!this.offer) {
      return '';
    }
    let codes = [];
    this.offer.flights.map(flight => {
      flight.segments.map(fs => {
        if (codes.indexOf(fs.detail.classOfService.code) === -1) {
          codes.push(fs.detail.classOfService.code);
        }
      });
    });

    return codes.sort((a, b) => {
      return a > b ? 1 : -1;
    }).join(', ');
  }

  getTravelersInfo() {
    if (!this.travelers) {
      return '';
    }
    let t = (new PairsPipe()).transform(this.travelers);
    let msg = [];
    t.map(item => {
      if (item[1]) {
        msg.push(item[1] + ' ' + item[0].toUpperCase());
      }
    });

    return msg.join(', ');
  }

  stepSelect(step: number) {
    this.clearPopupNotifications();
    let prevSelectedTab = this.selectedStep;
    this.selectedStep = step;
    if (this.selectedStep === BOOKING_STEPS.RESERVATION) {
      this.checkValidationForTabChange(prevSelectedTab);
      if (prevSelectedTab === BOOKING_STEPS.REMARKS && this.isRemarksStepChangeDisallowed) {
        this.selectedStep = prevSelectedTab;
        this.remarksSaveTriggered++;
      }
    } else if (this.selectedStep === BOOKING_STEPS.REMARKS) {
      this.isAgencyRemarksTemplateSelected = this.isCorporateRemarksTemplateSelected = false;
      this.checkValidationForTabChange(prevSelectedTab);
      this.setOfferInfo();
    }
    if (this.selectedStep > this.numberOfCompletedSteps) {
      this.numberOfCompletedSteps = this.selectedStep;
    }
  }

  checkValidationForTabChange(prevSelectedTab) {
    if (!this.validate()) {
      this.findInvalidControls(true);
      console.log(this.errors);
      this.selectedStep = prevSelectedTab;
      return;
    }
  }

  setOfferInfo() {
    let paxInfo = this.getPassengersInfo();
    paxInfo.map((passengerInfo, idx) => {
      passengerInfo.travelerReference = this.offer.passengers[idx]?.travelerReference;
    });
    this.offerInfo['origin'] = this.offer.flights[0].departure.airportCode;
    this.offerInfo['destination'] = this.offer.flights[0].arrival.airportCode;
    this.offerInfo['status'] = 'Pending';
    this.offerInfo['psgInfo'] = this.offer.price.consumer;
    this.offerInfo['passengers'] = paxInfo;
  }

  removeSeat(segmentSeat: any, seat: any) {
    let rowIdx = seat.location.row - this.seatMapService.segmentMatrix[segmentSeat].rows.first;
    this.seatMapService.selectSeat(segmentSeat, rowIdx, seat.location.column);
    this.onSeatSelect(null);
    this.showSelectedSeats();
  }

  getSeatPrice(segmentSeat: any, seat: any) {
    let rowIdx = seat.location.row - this.seatMapService.segmentMatrix[segmentSeat].rows.first;
    let price = this.seatMapService.allSeats[segmentSeat][this.seatMapService.getSeatCode(segmentSeat, rowIdx, seat.location.column)].price;
    return price;
  }

  onOfferExpired(isOfferExpired) {
    this.isOfferExpired = isOfferExpired;
    if (this.isOfferExpired) {
      this.refresh();
    }
  }

  getPaymentType() {
    this.paymentMethod = this.paymentObject?.method;
    return this.getPaymentMethod(this.paymentMethod);
  }

  getPaymentMethod(method) {
    switch (method) {
      case 'card':
        return 'Credit Card';
      case 'agencyCard':
        return 'Agency Card';
      case 'agencyCash':
        return 'Agency Cash';
      default:
        return 'none';
    }
  }

  checkServicesAdded() {
    if ((!this.offer.allowedRequests?.AddSeatsWithoutPayment && this.seatsPerSegment) ||
      (!this.offer.allowedRequests?.AddServicesWithoutPayment && this.selectedServices?.length > 0)) {
      return true;
    }
    return false;
  }

  checkIsFirstPassenger(psgRef) {
    return this.firstTravelerID === psgRef;
  }

  detectProfileTravelerChanges() {
    const passengersByType = [
      {adt: this.travelers.adt},
      {yad: this.travelers.yad},
      {chd: this.travelers.chd},
      {inf: this.travelers.inf}
    ];
    const beforeTravelers = {...this.travelerProfiles};
    const afterTravelers = {};
    passengersByType.map(passenger => {
      let counter = 0;
      const [psgType] = Object.keys(passenger);
      for (let i = 0; i < passenger[psgType]; i++) {
        const passKey = `passenger_${psgType}_${counter}`;
        afterTravelers[passKey] = this.form.controls[passKey].value;
        counter++;
      }
    });
    const changes = {};
    Object.entries(afterTravelers).map(traveler => {
      let key, data;
      [key, data] = traveler;
      changes[key] = [];
      const prevTraveler = {...beforeTravelers[key]};
      delete data.data.storeProfile;
      delete data.data.fqtvInfo;
      delete data.data.address.label;
      delete data.data.addresses;

      const processFields = (code: string, idx?: number) => {
        const fieldEntries = this.helpers.isNumber(idx) && data[code][idx] && typeof data[code][idx] === 'object'
          ? Object.entries(data[code][idx])
          : Object.entries(data[code]);
        fieldEntries.forEach(item => {
          let dKey, value;
          [dKey, value] = item;

          let oldValue = '';
          if (this.helpers.isNumber(idx) && prevTraveler[code] && prevTraveler[code][idx] && prevTraveler[code][idx].hasOwnProperty(dKey)) {
            oldValue = prevTraveler[code][idx][dKey];
          } else if (prevTraveler[code] && prevTraveler[code].hasOwnProperty(dKey)) {
            oldValue = prevTraveler[code][dKey];
          }
          let isChanged: boolean;
          if ((new IsArrayPipe).transform(value) || (new IsObjectPipe).transform(value)) {
            isChanged = JSON.stringify(oldValue) !== JSON.stringify(value);
          } else {
            isChanged = oldValue !== value;
          }
          if (isChanged) {
            changes[key].push({
              field: dKey,
              old: oldValue,
              new: value,
            });
          }
        });
      };

      processFields('documents', 0);
      processFields('data');
    });

    return changes;
  }

  loadConfirmStoreModal() {
    this.changes = this.detectProfileTravelerChanges();
    this.hasChanges = false;
    Object.entries(this.changes).map(item => {
      if ((<[]>item[1]).length) {
        this.hasChanges = true;
      }
    });
    if (this.form.get('corporateID').value && this.hasChanges) {
      const size = 'lg',
        windowClass = '';
      this.modalService.open(this.confirmStoreModal, {
        size, windowClass
      }).result.then((result) => {
      }, (reason) => {
        this.confirmCallback();
      });
    } else {
      this.confirmCallback();
    }
  }

  confirmStoreTravelers() {
    const request = {
      corporateID: this.form.get('corporateID').value,
      passengers: this.getPassengersInfo().filter(psg => psg.data.storeProfile),
    };

    return this.profileService.setTravelers(request.corporateID, request.passengers)
      // .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res: any) => {
        this._notificationSvc.success('SUCCESS', 'Travelers successfully saved', 0);
        this.confirmCallback();
        return true;
      }, err => {
        console.log(err);
      });
  }


  labelFor(field: string) {
    return this.labels[field];
  }

  valueFor(field, value: any) {
    if (value && (field === 'birthdate' || field === 'expirationDate')) {
      return HelpersService.getFormattedDate(value);
    }
    if (field === 'address.countryCode') {
      return this.countries[value];
    }
    return value || '(empty)';
  }

  isObject(obj: any) {
    return (new IsObjectPipe).transform(obj);
  }

  hasDiff(a: any, b: any) {
    return a != b;
  }

  getActiveIDsForChanges(changes) {
    const items = (new PairsPipe).transform(changes).map((item, idx) => 'passenger' + '_' + idx);

    return items.join(',');
  }

  preparePassengersForSeats(passengersInfo: any[]) {
    this.seatPassengers = [];
    passengersInfo
      .filter(p => p.passengerType !== 'INF')
      .map((p, idx) => {
        this.seatPassengers.push({
          travelerReference: this.offer.passengers[idx].travelerReference,
          data: {
            name: p.data.name,
            surname: p.data.surname,
          },
          passengerType: p.passengerType,
        });
      });
  }

  onServicesSelected(services) {
    this.selectedServices = services;
    this.getServicesPerSegment();
    this.refreshTotalPrice();
  }

  onSelectTitle(value, psgKey) {
    const title = this.offer.availableTitles?.find(t => t.hasOwnProperty(value));
    this.isGenderExists[psgKey] = !!title && !!title[value];
    const genderValue = this.isGenderExists[psgKey] ? this.helpers.capitalizeFirstLetter(title[value]) : '';
    this.form.get(psgKey).get('data').get('gender').setValue(genderValue);
  }

  getNumber($event: any) {
    // console.log('gn',$event);
  }

  telInputObject(psgIdx, $event: any) {
    this.telInputObjects[psgIdx] = $event;
    let selectedCountry = this.form.controls[psgIdx].get('data').get('phone').get('countryCode').value;
    if (!selectedCountry) {
      let savedTelCountry = this.ls.settings?.telCountryNumber;
      this.setPhoneCode(psgIdx, savedTelCountry?.dialCode || $event.s.dialCode);
      this.setPhoneCountryCodeISO(psgIdx, savedTelCountry?.iso || $event.s.iso2);
    }
    this.helpers.onTelInputObject(this.telInputObjects[psgIdx], selectedCountry);
  }

  onCountryChange(psgIdx: any, $event: any) {
    this.setPhoneCode(psgIdx, $event.dialCode);
    this.setPhoneCountryCodeISO(psgIdx, $event.iso2);
    this.helpers.saveTelCountryNumber($event);
  }

  passengerNameByRef(srv: any) {
    let psg = this.passengersInfo[this.passengerIdx[srv.travelerReferences]];
    return `${psg.data.name} ${psg.data.surname}`;
  }

  removeService(id: number) {
    this.selectedServices = this.selectedServices?.filter(service => service.serviceID !== id);
    this.getServicesPerSegment();
    this.refreshTotalPrice();
  }

  getServicesPerSegment() {
    const destinationData = {
      entireTrip: [],
      perLeg: {},
      perSegment: {}
    };

    this.selectedServices?.forEach(service => {
      this.helpers.splitServiceByDestinationType(service, this.segments, destinationData);
    });
    this.servicePerSegment = destinationData;
  }

  refreshTotalPrice() {
    let servicesPrice = 0.0;
    this.selectedServices.map(srv => {
      servicesPrice = (servicesPrice * 100 + srv.price.consumer.total * 100) / 100;
    });
    let seatsPrice = 0.0;
    if (this.showSeats) {
      Object.keys(this.showSeats).map(key => {
        this.showSeats[key].seats.map((item, index) => {
          seatsPrice = (seatsPrice * 100 + this.showSeats[key].seats[index].price.consumer.total * 100) / 100;
        });
      });
    }
    this.totalPrice = (this.originOfferPrice * 100 + servicesPrice * 100 + seatsPrice * 100) / 100;
    this.service.price.consumer.total = this.totalPrice;
  }

  getMetas() {
    let metas = {};

    const passengersByType = [
      {adt: this.travelers.adt},
      {yad: this.travelers.yad},
      {chd: this.travelers.chd},
      {inf: this.travelers.inf}
    ];

    let idx = 0;
    passengersByType.map(passenger => {
      let counter = 0;
      const [passengerKey] = Object.keys(passenger);
      for (let i = 0; i < passenger[passengerKey]; i++) {
        let id = `passenger_${passengerKey}_${counter}`;
        let passengerObject = JSON.parse(JSON.stringify(this.form.controls[id].value));
        if (passengerObject.metas) {
          Object.keys(passengerObject.metas).forEach(item => {
            let key = item.replace("%idx%", idx.toString());
            metas[key] = passengerObject.metas[item];
          });
        }
        counter++;
        idx++;
      }
      return passenger;
    });

    return metas;
  }

  onAdultReferenceChange(travelerReference: string, index: number, psgKey: string) {
    if (travelerReference === 'clear') {
      this.form.get(psgKey).get('adultReference').setValue('');
    }
    this.adultReferences[index] = travelerReference;
  }

  onSelectAirlineDesignator(passenger: string, airlineDesignator: string) {
    if (airlineDesignator) {
      this.form.get(passenger)['controls'].data.controls['fqtvInfo'].controls['airlineID'].setValue(airlineDesignator.toUpperCase());
    } else {
      this.form.get(passenger)['controls'].data.controls['fqtvInfo'].controls['airlineID'].setValue('');
    }
  }

  resetAirlineDesignator(travelerType, idx) {
    this.form.get('passenger_' + travelerType[0] + '_' + idx + '.data.fqtvInfo.airlineID').setValue('');
  }

  redirectToNewUrl() {
    const owner = this.route.snapshot.paramMap.get('owner');
    const travelers = this.route.snapshot.paramMap.get('travelers');
    const od = this.route.snapshot.paramMap.get('od');
    if (owner || travelers || od) {
      this.router.navigateByUrl(`offer/${this.offerID}`);
    }
  }

  isLoyaltyProgramAccountEnabled() {
    return this.loyaltyProgramAccountSupportedProviders.indexOf(this.offer.owner) > -1;
  }

  closePaxIncorrectDataPopover() {
    this.form.statusChanges.subscribe(status => {
      this.findInvalidControls();
      if (status === 'VALID' && this.popover?.isOpen()) {
        this.popover.close();
      }
    });
  }

  findInvalidControls(changeTab= false) {
    const controls = this.form.controls;
    let isFirst = true;
    for (const name in controls) {
      if (controls[name].invalid && name.includes('passenger')) {
        if (changeTab && isFirst && this.tabset) {
          this.tabset.activeId = name;
          isFirst = false;
        }
      }
    }
  }

  getTemplatesAndMandatoryRemarks(templates: any[]) {
    if (templates) {
      const mandatoryRemarks = templates.find(template => template.neededOnCreation || template.needed_on_creation);
      if (mandatoryRemarks) {
        templates = templates.filter(template => template.neededOnCreation || template.needed_on_creation);
      }
      return {templates, mandatoryRemarks};
    }
  }

  updateAgencyRemarks(remarksData) {
    this.agencyRemarksData = remarksData;
    setTimeout(() => this.changeStepIfRemarksAreSaved(remarksData));
  }

  updateCorporateRemarks(remarksData) {
    this.corporateRemarksData = remarksData;
    setTimeout(() => this.changeStepIfRemarksAreSaved(remarksData));
  }

  changeStepIfRemarksAreSaved(remarksData) {
    if (!remarksData?.result || this.isRemarksStepChangeDisallowed) {
      return;
    }

    if (this.remarksSaveTriggered) {
      this.selectedStep = BOOKING_STEPS.RESERVATION;
      this.numberOfCompletedSteps = this.selectedStep;
    }
    this.remarksSaveTriggered = 0;
  }

  toggleReadWarnings() {
    this.allWarningsAreShown = !this.allWarningsAreShown;
  }

  toggleDisplayWarnings() {
    this.showWarnings = !this.showWarnings;
  }

  getWarningsHeight() {
    setTimeout(() => {
      if (this.warnings) {
        this.allWarningsAreShown = this.warnings.nativeElement.offsetHeight < 190;
        this.isWarningsReadBtnNeeded = !this.allWarningsAreShown;
      }
    }, 100);
  }

  addAddress(address: string, psgKey: string) {
    this.addresses[address] = true;
    if (address === 'destination') {
      const control = this.buildAddress('addressAtDestination');
      (this.form.get(psgKey).get('data.addresses') as FormArray).push(control);
    }
    this.form.get(psgKey).get('data.addressType').setValue('');
  }

  removeAddress(address: string, controlName: string, index = 0) {
    const control = this.form.get(controlName);
    if (address === 'destination') {
      (control as FormArray).removeAt(index);
      if (control.value.length === 0) {
        this.addresses.destination = false;
      }
    } else {
      this.addresses.home = false;
      control.reset({
        countryCode: '',
        label: 'addressAtHome'
      });
    }
  }

  buildAddress(label: 'addressAtHome' | 'addressAtDestination') {
    return this.fb.group({
      'label': [label],
      'countryCode': [''],
      'cityName': [''],
      'postalCode': [''],
      'street': [''],
    });
  }

  addDocument(psgKey: string, document?: any) {
    const control = this.buildDocument(document);
    (this.form.get(psgKey).get('documents') as FormArray).push(control);
  }

  removeDocument(controlName: string, index: number) {
    const arrayControl = this.form.get(controlName) as FormArray;
    arrayControl.removeAt(index);
  }

  buildDocument(document?: any) {
    const documentControl = this.fb.group({
      'documentID': [document?.documentID || ''],
      'documentType': [document?.documentType || ''],
      'expirationDate': [document?.expirationDate ? HelpersService.getStructFromDate(new Date(document.expirationDate)) : null],
      'fiscalName': [document?.fiscalName || ''],
      'issuingCountryCode': [document?.issuingCountryCode || ''],
      'citizenshipCountryCode': [document?.citizenshipCountryCode || ''],
      'residenceCountryCode': [document?.residenceCountryCode || ''],
    });

    // let documentIdControl = documentControl.get('documentID');
    // let documentTypeControl = documentControl.get('documentType');
    // documentTypeControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((value => {
    //   documentIdControl.setValidators([Validators.required, DocumentIdValidator(value)]);
    //   documentIdControl.updateValueAndValidity({emitEvent: false, onlySelf: false});
    // }));

    return documentControl;
  }

  getPreviousPassengersInfo() {
    if (!this.helpers.isPrivateMode) {
      const storeName = 'passengers_info';
      this.dbService.getAll(storeName).subscribe((passengersData: any[]) => {
        if (passengersData.length) {
          const data = passengersData[passengersData.length - 1];
          this.previousPassengersData = data.passengers;
          if (this.previousPassengersData.length === this.offer.passengers.length && data.routeInfo === this.routeInfo) {
            this.fillPassengersData = true;
            this.fillPreviousPassengersData();
          }
          this.dbService.clear(storeName).subscribe((successDeleted) => {});
        }
      });
    }
  }

  fillPreviousPassengersData() {
    let psgPrefix = 'passenger_';
    this.previousPassengersData.map(passenger => {
      for (let i = 0; i < this.previousPassengersData.length; i++) {
        let psgKey = psgPrefix + passenger.passengerType.toLowerCase() + '_' + i;
        if (this.form.controls[psgKey] && !this.form.controls[psgKey].dirty) {
          let control = this.form.controls[psgKey];
          control.markAsDirty();
          let passengerDetails = this.getPassengerDetails(passenger, psgKey);
          control.patchValue(passengerDetails);
          if (this.checkIsFirstPassenger(psgKey)) {
            let contactDetails = this.getContactDetails(passenger);
            control.patchValue(contactDetails);
            if (this.telInputObjects[psgKey]) {
              this.telInputObjects[psgKey].setCountry(contactDetails.data.phone.countryCode);
            }
          }
          break;
        }
      }
    });
    this.fillPassengersData = false;
  }

  getPassengerDetails(passenger, psgKey: string) {
    let psg = JSON.parse(JSON.stringify(passenger));
    let passengerDetails: any = {};

    if (psg.documents?.length) {
      psg.documents.forEach((document) => {
        this.addDocument(psgKey, document);
      });
    }

    if (psg.data) {
      passengerDetails.data = {
        birthdate: psg.data.birthdate ? this.helpers.formatDateToNgbDateStruct(psg.data.birthdate) : null,
        title: psg.data.title,
        gender: psg.data.gender,
        name: psg.data.name,
        surname: psg.data.surname,
        fqtvInfo: {
          account: {
            number: psg.data.fqtvInfo?.account?.number
          },
          airlineID: psg.data.fqtvInfo?.airlineID
        }
      };
    }

    return passengerDetails;
  }

  getContactDetails(psg) {
    const phoneNumber = psg.data.phone ? parsePhoneNumber('+' + psg.data.phone) : null;
    return {
      data: {
        phone: {
          countryCode: phoneNumber?.country || this.ls.settings?.telCountryNumber?.iso || 'es',
          number: phoneNumber?.nationalNumber
        },
        email: psg.data.email,
        address: {
          countryCode: psg.data.address.countryCode,
          cityName: psg.data.address.cityName,
          postalCode: psg.data.address.postalCode,
          street: psg.data.address.street,
        }
      }
    };
  }

  checkGodMode() {
    this.helpers.getGodModeData().subscribe((godMode) => {
      if (godMode.isTriggered) {
        this.updateActions(godMode.isEnabled);
      }
    });
  }

  updateActions(isGodModeEnabled: boolean) {
    if (!this.isFirstStateSaved) {
      this.isFirstStateSaved = true;

      // save first state of all actions
      this.bookingActions.paymentSettings = Object.assign({}, this.paymentSettings);
      this.bookingActions.seatAvailabilityEnabled = this.seatAvailabilityEnabled;
      this.bookingActions.serviceListEnabled = this.serviceListEnabled;
      this.bookingActions.orderCreateWithPaymentEnabled = this.orderCreateWithPaymentEnabled;
    }

    if (isGodModeEnabled) {
      // enable access to all possible actions
      Object.keys(this.paymentSettings).map(key => {
        this.paymentSettings[key] = true;
      }); // enable all allowed payment methods
      this.seatAvailabilityEnabled = true; // select seats button
      this.serviceListEnabled = true; // select services button
      this.orderCreateWithPaymentEnabled = true; // set payment details button
    } else {
      // return all actions to their original state
      this.paymentSettings = Object.assign({}, this.bookingActions.paymentSettings);
      this.seatAvailabilityEnabled = this.bookingActions.seatAvailabilityEnabled;
      this.serviceListEnabled = this.bookingActions.serviceListEnabled;
      this.orderCreateWithPaymentEnabled = this.bookingActions.orderCreateWithPaymentEnabled;
    }
  }

  refresh(): void {
    window.location.reload();
  }

  get isOfferStepCompleted() {
    return this.numberOfCompletedSteps > this.bookingSteps.OFFER && this.selectedStep !== this.bookingSteps.OFFER;
  }

  get isPaxStepCompleted() {
    return this.numberOfCompletedSteps > this.bookingSteps.PAX && this.selectedStep !== this.bookingSteps.PAX;
  }

  get isRemarksStepCompleted() {
    return this.numberOfCompletedSteps > this.bookingSteps.REMARKS && this.selectedStep !== this.bookingSteps.REMARKS;
  }

  get isReservationStepDisabled() {
    return this.hasUnsavedMandatoryRemarks || this.numberOfCompletedSteps < this.bookingSteps.REMARKS;
  }

  get isBookAndIssueButtonDisabled() {
    return this.getPaymentType() === 'none' || (this.hasRemarkTemplates && !this.hasRemarkResult);
  }

  get isBookOnHoldButtonDisabled() {
    return this.getPaymentType() !== 'none' || this.offer.owner === 'VY' || this.checkServicesAdded();
  }

  get fiscalNameValidators() {
    return this.offer.owner === 'LA' ? Validators.pattern(this.fiscalNamePattern) : [];
  }

  get isRemarksStepChangeDisallowed() {
    return this.hasUnsavedMandatoryRemarks || this.isAgencyRemarksTemplateSelected || this.isCorporateRemarksTemplateSelected;
  }

  get hasRemarkTemplates() {
    return this.agencyRemarkTemplates.length || this.corporateRemarkTemplates.length;
  }

  get hasRemarkResult() {
    return this.agencyRemarksData?.result || this.corporateRemarksData?.result;
  }

  get hasMandatoryRemarks() {
    return this.mandatoryAgencyRemarks || this.mandatoryCorporateRemarks;
  }

  get hasUnsavedMandatoryAgencyRemarks() {
    return this.mandatoryAgencyRemarks && this.hasUnsavedRemarks;
  }

  get hasUnsavedMandatoryCorporateRemarks() {
    return this.mandatoryCorporateRemarks && this.hasUnsavedRemarks;
  }

  get hasUnsavedRemarks() {
    return (
      (!this.corporateRemarksData?.result && this.corporateRemarkTemplates.length > 0) ||
      (!this.agencyRemarksData?.result && this.agencyRemarkTemplates.length > 0)
    );
  }

  get hasUnsavedMandatoryRemarks() {
    return (
      this.hasUnsavedMandatoryAgencyRemarks ||
      this.hasUnsavedMandatoryCorporateRemarks
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}
